import { ff } from '@atlassian/jira-feature-flagging';
import { createStateHook } from '@atlassian/react-sweet-state';
import { DENSITY_FULL } from '../../common/constants';
import { FeaturesStore } from './index';

export const useIsDensityFull = createStateHook(FeaturesStore, {
	selector: (state) => state.density === DENSITY_FULL,
});

export const useIsColumnResizingEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isColumnResizingEnabled,
});

export const useIsFixedTableLayoutEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isFixedTableLayoutEnabled,
});

export const useIsInlineEditingEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isInlineEditingEnabled,
});

export const useIsIssueCreateEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isIssueCreateEnabled,
});

export const useIsInfiniteScrollingEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isInfiniteScrollingEnabled,
});

export const useIsSiblingIssueCreateEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isIssueCreateEnabled && state.isIssueRankingEnabled,
});

export const useIsIssueRankingEnabled = createStateHook(FeaturesStore, {
	selector: (state) => ff('jsw.enable-nin-rank-dnd') && state.isIssueRankingEnabled,
});

export const useIsIssueHierarchyEnabled = createStateHook(FeaturesStore, {
	selector: (state) => state.isIssueHierarchyEnabled,
});

export const useIssueCreateProjectKey = createStateHook(FeaturesStore, {
	selector: (state) => state.issueCreateProjectKey,
});

export const useSortField = createStateHook(FeaturesStore, {
	selector: (state) => state.sortField,
});

export const useSortDirection = createStateHook(FeaturesStore, {
	selector: (state) => state.sortDirection,
});
