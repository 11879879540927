import { createStore, createContainer, type Action } from '@atlassian/react-sweet-state';
import { DENSITY_COMPACT, SORT_DESC } from '../../common/constants';
import type { State, Props } from './types';

const initialState: State = {
	density: DENSITY_COMPACT,
	isColumnResizingEnabled: true,
	isFixedTableLayoutEnabled: true,
	isInlineEditingEnabled: true,
	isIssueCreateEnabled: false,
	issueCreateProjectKey: null,
	isInfiniteScrollingEnabled: false,
	isIssueRankingEnabled: false,
	isIssueRankDescending: false,
	isIssueHierarchyEnabled: false,
};

/** Container to store the configuration state of various table features. */
export const FeaturesContainer = createContainer<Props>();

const updateStore: Action<State, Props> = (
	{ setState },
	{
		density,
		isColumnResizingEnabled,
		isInlineEditingEnabled,
		isIssueCreateEnabled,
		issueCreateProjectKey,
		isInfiniteScrollingEnabled,
		isOrderedByRank,
		sortDirection,
		sortField,
		isIssueHierarchyEnabled,
	},
) => {
	setState({
		density,
		isColumnResizingEnabled,
		isFixedTableLayoutEnabled: isColumnResizingEnabled,
		isInlineEditingEnabled,
		isIssueCreateEnabled: isIssueCreateEnabled && Boolean(issueCreateProjectKey),
		issueCreateProjectKey: issueCreateProjectKey ?? null,
		isInfiniteScrollingEnabled,
		isIssueHierarchyEnabled,
		isIssueRankingEnabled: isOrderedByRank,
		isIssueRankDescending: sortDirection === SORT_DESC,
		sortDirection,
		sortField,
	});
};

export const FeaturesStore = createStore({
	initialState,
	actions: {},
	containedBy: FeaturesContainer,
	handlers: {
		onInit: () => updateStore,
		onContainerUpdate: () => updateStore,
	},
});
